.infinitycontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw; 
  }
  
  
  .gradient-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    border-radius: 50%; /* Ensures the shape is a circle */
    background: none;
    border: 1px solid transparent; /* Transparent border to make room for the gradient */
    box-shadow: 
      0 0 0 10px transparent,         /* Initial transparent space */
      0 0 0 10px rgba(255,182,193,1);
  }
  