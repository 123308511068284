@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-20px);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Applying animations */
.animate-fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.animate-pop-in {
    animation: popIn 0.6s ease-in-out;
}

.animate-slide-in {
    animation: slideIn 0.4s ease-in-out;
}

/* Enhancing hover states with transformations */
.hover:scale-105:hover {
    transform: scale(1.05);
}