.fa-refresh:hover {
  animation: spin 2s infinite linear;
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: pink;
  /* Set the color of the thumb */
  border-radius: 4px;
  /* Set the border radius to make it round */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the color of the track */
}


.my-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 10s linear infinite;
  display: inline-block;
      font-size: 190px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Great+Vibes&family=Newsreader:opsz@6..72&family=Pacifico&family=Parisienne&family=Poppins:wght@500&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

/* Login Page Start */

.gradient-custom-2 {
    /* fallback for old browsers */
    background: #30cfd0;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(48, 207, 208, 0.5), rgba(51, 8, 103, 0.5));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgba(48, 207, 208, 0.5), rgba(51, 8, 103, 0.5))
}

.input-box .input{
    width: 100%;
    height: 40px;
    margin: 10px;
    background: transparent;
    border: 2px solid #030303;
    outline: none;
    border-radius: 40px;
    font-size: 1em;
    color: #353535;
    padding: 0 25px 0 45px;
    font-family: 'Newsreader', serif;
}

.text-center img,
.image_banner img{
    width: 100%;
    height:50%;
}

.border-around-b {
    border: 2px solid #000; 
    padding: 5px; 
    margin-bottom: 10px; 
    display: inline-block; 
    border-radius: 10px; 
}

#login-btn:hover {
    opacity: 0.9 !important;
    background-color: #720052 !important;
}


/* Login Page End */


/* Dashboard Page Start */



.card-dash {
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 210px 250px 20px;
  grid-template-areas: "image" "text" "stats";
  border-radius: 18px;
  background: #1d1d1d;
  color: white;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  font-family: roboto;
  text-align: justify;
  cursor: pointer;
  margin: 30px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  
}

.rgb::after {
  content: "";
  background: linear-gradient(45deg,
          #ff0000 0%,
          #ff9a00 10%,
          #d0de21 20%,
          #4fdc4a 30%,
          #3fdad8 40%,
          #2fc9e2 50%,
          #1c7fee 60%,
          #5f15f2 70%,
          #ba0cf8 80%,
          #fb07d9 90%,
          #ff0000 100%) repeat 0% 0% / 300% 100%;
  position: absolute;
  inset: -3px;
  border-radius: 16px;
  filter: blur(8px);
  transform: translateZ(-1px);
  /*or z-index */
  animation: rgb 6s linear infinite;
}

@keyframes rgb {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}

.js-tilt-glare {
  border-radius: 18px;
}

.card-image {
  grid-area: image;
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../images/Whatsapp.png");
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-size: cover;
  width: 200px;
  height: 200px;
  padding: 50px;
  align-items: center;
}

.card-text {
  grid-area: text;
  margin: 25px;
  transform: translateZ(30px);
}

.card-text .date {
  color: rgb(255, 7, 110);
  font-size: 13px;
}

.card-text p {
  color: grey;
  font-size: 14px;
  font-weight: 300;
}

.card-text h2 {
  margin-top: 0px;
  font-size: 28px;
}

.card-stats {
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: rgb(255, 7, 110);
}

.card-stats .stat {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.card-stats .border {
  border-left: 1px solid rgb(172, 26, 87);
  border-right: 1px solid rgb(172, 26, 87);
}

.card-stats .value {
  font-size: 22px;
  font-weight: 500;
}

.card-stats .value sup {
  font-size: 12px;
}

.card-stats .type {
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
}


/*card2*/
.card-image.card2 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../images/SMS\ \(2\).png");
  background-size: cover;
  width: 200px;
  height: 200px;
  padding: 50px;
  align-items: center;
}

.card-text.card2 .date {
  color: rgb(255, 77, 7);
}

.card-stats.card2 .border {
  border-left: 1px solid rgb(185, 67, 20);
  border-right: 1px solid rgb(185, 67, 20);
}

.card-stats.card2 {
  background: rgb(255, 77, 7);
}

/*card3*/
.card-image.card3 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../images/email.png");
  background-size: cover;
  width: 200px;
  height: 200px;
  padding: 50px;
  align-items: center;
}

.card-text.card3 .date {
  color: rgb(0, 189, 63);
}

.card-stats.card3 .border {
  border-left: 1px solid rgb(14, 122, 50);
  border-right: 1px solid rgb(14, 122, 50);
}

.card-stats.card3 {
  background: rgb(0, 189, 63);
}

.card-dash:hover {
  transform: scale(1.05); /* Increase the size on hover */
  transition: transform 0.5s ease; /* Add smooth transition */
}

/*card4*/
.card-image.card4 {
  background: linear-gradient(#fff0 0%, #fff0 70%, #1d1d1d 100%), url("../images/call2.png");
  background-size: cover;
  width: 200px;
  height: 200px;
  padding: 50px;
  align-items: center;
}

.card-text.card4 .date {
  color: rgb(0, 189, 63);
}

.card-stats.card4 .border {
  border-left: 1px solid rgb(14, 122, 50);
  border-right: 1px solid rgb(14, 122, 50);
}

.card-stats.card4 {
  background: rgb(0, 189, 63);
}

.card-dash:hover {
  transform: scale(1.05); /* Increase the size on hover */
  transition: transform 0.5s ease; /* Add smooth transition */
}
  

@media (min-width: 768px) {
    .gradient-form {
        height: 100vh !important;
    }
}

@media (min-width: 769px) {
    .gradient-custom-2 {
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
    }
}


.animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 200px;
  height: 200px;
}

@keyframes animation {
  0% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -105;
  }
  50% {
    stroke-dasharray: 80 10;
    stroke-dashoffset: -160;
  }
  100% {
    stroke-dasharray: 1 98;
    stroke-dashoffset: -300;
  }
}

#spinner {
  transform-origin: center;
  animation-name: animation;
  animation-duration: 1.2s;
  animation-timing-function: cubic-bezier;
  animation-iteration-count: infinite;
}

.maincontainer {
  overflow: hidden;
}
